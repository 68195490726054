<template>
    <div class="signup">
        <div class="left" :style="{'background-image': `url(${require(`@/assets/img/${language}.png`)})`}"></div>
        <div class="right">
            <img class="close" :src="require('@/assets/img/close.svg')" alt="close" @click="$router.push('/')">

            <div class="signup-container">
                <div class="upper">
                    <img :src="require('@/assets/img/sign_in_icon.svg')" alt="icon" class="icon">
                    <img :src="require('@/assets/img/logo.png')" alt="logo" class="logo">
                    <p class="title">{{ $t('sign_up_on') }}</p>
                    <p class="desc">{{ $t('sign_up_desc') }}</p>
                </div>

                <div class="lower">
                    <form @submit.prevent="signup()" class="signup-form">
                        <input
                            type="text"
                            :placeholder="$t('name')"
                            v-model="name"
                            required
                        />
                        <p class="_error" v-if="error.name">{{ error.name[0] }}</p>
                        
                        <input
                            type="email"
                            :placeholder="$t('email_address')"
                            v-model="email"
                            required
                        />
                        <p class="_error" v-if="error.email">{{ error.email[0] }}</p>

                        <div class="password-wrapper">
                            <input
                                class="password"
                                :type="isShow ? 'text' : 'password'"
                                :placeholder="$t('password')"
                                v-model="pass"
                                required
                            />
                            <p class="_error" v-if="error.password">{{ error.password[0] }}</p>

                            <img 
                                v-if="isShow"
                                :src="require('@/assets/img/icon_hide.png')"
                                alt="show"
                                @click="isShow = !isShow"
                            >
                            <img
                                v-else
                                :src="require('@/assets/img/icon_show.png')"
                                alt="hide"
                                @click="isShow = !isShow"
                            >
                        </div>

                        <p class="tip">{{ $t('sign_up_tip') }}</p>

                        <div class="password-wrapper">
                            <input
                                class="password"
                                :type="confirmIsShow ? 'text' : 'password'"
                                :placeholder="$t('retype_password')"
                                v-model="confirmPass"
                                required
                            />
                            <p class="_error" v-if="error.password_confirmation">{{ error.password_confirmation[0] }}</p>

                            <img 
                                v-if="confirmIsShow"
                                :src="require('@/assets/img/icon_hide.png')"
                                alt="show"
                                @click="confirmIsShow = !confirmIsShow"
                            >
                            <img
                                v-else
                                :src="require('@/assets/img/icon_show.png')"
                                alt="hide"
                                @click="confirmIsShow = !confirmIsShow"
                            >
                        </div>

                        <div class="term-wrapper">
                            <input class="checkbox" type="checkbox" required/>
                            <p>{{ $t('i_accept') }}</p>
                            <router-link to="/terms" target="_blank" class="term">{{ $t('terms') }}</router-link>
                        </div>

                        <button type="submit" class="submit" :disabled="loading">{{ $t('sign_up') }}</button>
                    </form>
                </div>
            </div>

            <div class="footer">
                <p>{{ $t('already_have_account') }}</p>
                <router-link to="/sign-in" class="signin">{{ $t('sign_in') }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data () {
        return {
            isShow: false,
            confirmIsShow: false,

            name: '',
            email: '',
            pass: '',
            confirmPass: ''
        }
    },

    created () {
        this.$store.commit('SET_SIGN_UP_ERROR', {})
    },

    computed: {
        ...mapState({
            error: state => state.signUpError,
            loading: state => state.loading,
            language: state => state.language
        })
    },

    methods: {
        async signup () {
            if (this.pass !== this.confirmPass) {
                this.$store.commit('SET_SIGN_UP_ERROR', {
                    password_confirmation: [this.$t('password_not_match')]
                })
                return
            }

            if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_SERVER !== 'test') {
                // const isEmailVerified = await this.$options.filters.verifyEmail(this.email)
                const isEmailVerified = await this.$store.dispatch('checkEmail', {email: this.email})

                if (!isEmailVerified) {
                    this.$store.commit('SET_SIGN_UP_ERROR', {
                        email: [this.$t('error_email_not_valid')]
                    })

                    this.$store.commit('SET_LOADING', false)
                    return
                }
            }

            let form = new FormData

            form.append('name', this.name)
            form.append('email', this.email)
            form.append('password', this.pass)
            form.append('password_confirmation', this.confirmPass)

            this.$store.dispatch('signUp', form)
        }
    }
}
</script>

<style lang="scss" scoped>
.signup {
    display: flex;

    .left {
        @include desktop {
            flex: 1;
            height: 100vh;
            // background: #47435c url('~@/assets/img/sign_placeholder.jpg') no-repeat center top;
            background-repeat: no-repeat;
            background-size: cover;
            // opacity: 0.8;
            border-radius: 0 4px 4px 0;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;

        .close {
            cursor: pointer;
            position: absolute;
            top: 30px;
            right: 30px;

            width: 15px;
            height: 15px;

            @include desktop {
                width: unset;
                height: unset;
            }
        }

        .signup-container {
            padding: 0 20px;
            // margin: auto;
            margin: 70px auto 20px;

            @include desktop {
                margin: auto;
                width: 425px;
            }

            .upper {
                margin-bottom: 20px;

                .icon {
                    display: none;

                    @include desktop {
                        display: block;
                    }
                }

                .logo {
                    display: block;
                    width: 150px;
                    height: 100px;
                    margin: auto;

                    @include desktop {
                        display: none;
                    }
                }

                .title {
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 600;
                    font-size: 24px;
                    margin-bottom: 12px;
                    // color: #00AEEF;
                }

                .desc {
                    color: #B8B8B8;
                    font-size: 14px;
                    margin-bottom: 40px;
                }
            }

            .lower {
                display: flex;
                flex-direction: column;

                .password-wrapper {
                    width: 100%;
                    position: relative;

                    .password {
                        padding-right: 55px;
                    }

                    img {
                        position: absolute;
                        right: 20px;
                        width: 24px;
                        height: 24px;
                        top: 18px;
                    }
                }

                .tip {
                    color: #B8B8B8;
                    font-size: 12px;
                    margin-bottom: 16px;
                }

                ._error {
                    margin-bottom: 10px;
                }

                input {
                    width: 100%;
                    border-radius: 4px;
                    border: 1px solid #000000;
                    padding: 20px 15px;
                    font-size: 14px;
                    color: #00000099;
                    margin-bottom: 16px;
                }

                .term-wrapper {
                    display: flex;
                    align-items: center;
                    color: #00000099;
                    margin-bottom: 20px;

                    .checkbox {
                        width: 18px;
                        height: 18px;
                        margin-bottom: 0;
                        margin-right: 10px;
                    }

                    p {
                        font-size: 14px;
                        margin-right: 3px;
                    }

                    .term {
                        text-decoration: underline;
                    }
                }

                .submit {
                    width: 100%;
                    background-color: #00AEEF;
                    color: #FFFFFF;
                    font-weight: 600;
                    font-size: 16px;
                    padding: 18px;

                    &:disabled {
                        opacity: 0.7;
                        pointer-events: none;
                    }
                }
            }
        }

        .footer {
            margin: 0 auto;
            justify-content: center;
            display: flex;
            padding-bottom: 30px;

            @include desktop { 
                width: 425px;
            }

            p {
                font-size: 16px;
                margin-right: 3px;
            }

            .signin {
                color: #00AEEF;
                font-size: 16px;
            }
        }
    }
}
</style>